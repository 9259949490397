import React, {useState, useEffect} from "react";

import {Button, Flex, Form, Input, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import "../UserAdministrationPage/UserAdministrationPage.css";
import UserAdministrationForm
    from "../../components/Administration/Users/UserAdministrationForm/UserAdministrationForm";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import UserAdministrationFilter
    from "../../components/Administration/Users/UserAdministrationFilter/UserAdministrationFilter";
import UserAdministrationTable
    from "../../components/Administration/Users/UserAdministrationTable/UserAdministrationTable";

import "../../api/axiosInstance";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";
import PasswordChangeForm from "../../components/Administration/Users/PasswordChangeForm/PasswordChangeForm";

const UsersComponent = (props) => {
    const [mode, setMode] = useState("insert");

    const [filterForm] = Form.useForm();
    const [userDataForm] = Form.useForm();
    const [passwordChangeForm] = Form.useForm();

    const [rowData, setRowData] = useState();

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(false);
    const [userAdministrationModalOpened, setUserAdministrationModalOpened] = useState(false);
    const [passwordChangeModalOpened, setPasswordChangeModalOpened] = useState(false);

    const [initialValues, setInitialValues] = useState({
        jobTitle: 'inzenjer',
        address: {city: ""},
        role: {id: ""}
    });

    const {token} = theme.useToken();

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const fetchUsers = async (values) => {
        const response = await axiosInstance.post(
            `/api/v1/users/find-by?page=${pageNumber - 1}&size=${pageSize}`,
            {
                firstName: values?.firstName,
                lastName: values?.lastName,
                active: values?.active,
            }
        );

        setTableData(
            response?.data?.content.map((element) => {
                return {
                    ...element,
                    key: element.id,
                    addressStr: element.address.street
                        ? `${element.address.street} ${
                            element.address.houseNumber ? element.address.houseNumber : ""
                        } ${
                            element.address.postNumber ? element.address.postNumber : ""
                        } ${element.address.city ? element.address.city : ""}`
                        : "N/A",
                    activeStr: element.active ? "Aktivan" : "Neaktivan",
                    roleStr: element.role.name,
                };
            })
        );
        setTotalRows(response?.data?.totalElements);
    };

    useEffect(() => {
        fetchUsers({firstName: "", lastName: "", active: ""});
    }, [pageNumber, pageSize]);

    const onFormFilterSubmit = async (values) => {
        fetchUsers(values);
    };

    const onUserDataSubmit = (values) => {
        if (mode === "insert") {
            axiosInstance
                .post("/api/v1/users", values)
                .then((res) => {
                    setLoading(false);
                    setUserAdministrationModalOpened(false);
                    userDataForm.resetFields();
                    toast.success("Zaposlenik uspješno spremljen!");
                    fetchUsers({firstName: "", lastName: "", active: ""});
                })
                .catch((ex) => {
                    setLoading(false);
                    toast.error(
                        "Došlo je do greške prilikom spremanja zaposlenika! " +
                        ex.response.data.errorMessage
                    );
                });
        } else if (mode === "edit") {
            axiosInstance
                .patch("/api/v1/users", values)
                .then((res) => {
                    setLoading(false);
                    setUserAdministrationModalOpened(false);
                    userDataForm.resetFields();
                    toast.success("Zaposlenik uspješno ažuriran!");
                    fetchUsers({firstName: "", lastName: "", active: ""});
                })
                .catch((ex) => {
                    setLoading(false);
                    toast.error(
                        "Došlo je do greške prilikom ažuriranja zaposlenika! " +
                        ex.response.data.errorMessage
                    );
                });
        }
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleSubmitUser = async () => {
        try {
            const validationResult = await userDataForm.validateFields();
            if (!validationResult.errorFields) {
                userDataForm.submit();
                setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    const userDataFormTitle = () => {
        if (mode === "insert") {
            return "Novi zaposlenik";
        } else if (mode === "edit") {
            return "Uređivanje zaposlenika";
        } else {
            return "Pregled detalja zaposlenika";
        }
    };

    return (
        <div className={"users-page"} style={pageStyle}>
            <h3>Administracija zaposlenika</h3>
            <UserAdministrationFilter
                form={filterForm}
                onFormFilterSubmit={onFormFilterSubmit}
            ></UserAdministrationFilter>
            <Flex vertical={false} justify="right" className="add-user-wrapper">
                <Button
                    type="dashed"
                    icon={<PlusOutlined/>}
                    style={{marginTop: "32px"}}
                    onClick={() => {
                        userDataForm.resetFields();
                        setMode("insert");
                        setUserAdministrationModalOpened(true);
                    }}
                >
                    Novi zaposlenik
                </Button>
            </Flex>
            <UserAdministrationTable
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setUserAdministrationModalOpened={setUserAdministrationModalOpened}
                setPasswordChangeModalOpened={setPasswordChangeModalOpened}
                setRowData={setRowData}
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={() =>
                    fetchUsers({firstName: "", lastName: "", active: ""})
                }
            />
            <ModalComponent
                title={userDataFormTitle()}
                show={userAdministrationModalOpened}
                onOk={handleSubmitUser}
                onCancel={() => {
                    setUserAdministrationModalOpened(false);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"580px"}
            >
                <UserAdministrationForm
                    form={userDataForm}
                    mode={mode}
                    onFinish={onUserDataSubmit}
                    initialValues={mode === 'insert' ? initialValues : rowData}
                    disabled={mode === "preview"}
                ></UserAdministrationForm>
            </ModalComponent>
            <ModalComponent
                title={"Promjena lozinke"}
                show={passwordChangeModalOpened}
                onOk={async () => {
                    try {
                        const validationResult = await passwordChangeForm.validateFields();
                        if (!validationResult.errorFields) {
                            passwordChangeForm.submit();
                        }
                    } catch (ex) {
                        console.log(ex);
                    }

                }}
                onCancel={() => {
                    passwordChangeForm.resetFields();
                    setPasswordChangeModalOpened(false);
                }}
                loading={loading}
                width={"580px"}
            >
                <PasswordChangeForm
                    form={passwordChangeForm}
                    rowData={rowData}
                    setPasswordChangeModalOpened={setPasswordChangeModalOpened}
                />
            </ModalComponent>
        </div>
    );
};

export default UsersComponent;
